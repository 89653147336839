export function Skills({}) {
  const listClass = "font-accent cursor-pointer text-sm hover:text-base transition-all";
  return (
    <section className="m-auto w-3/4 max-w-xl items-center flex flex-col text-center">
      <ul className="flex gap-4 flex-wrap items-center justify-center">
        <li className={listClass}>Javascript / Typescript</li>
        <li className={listClass}>ReactJS / NextJS</li>
        <li className={listClass}>ReactNative</li>
        <li className={listClass}>Angular</li>
        <li className={listClass}>PHP</li>
        <li className={listClass}>Laravel</li>
        <li className={listClass}>Wordpress</li>
        <li className={listClass}>Shopify</li>
        <li className={listClass}>SQL / NoSQL</li>
        <li className={listClass}>AWS / GCP / Azure</li>
        <li className={listClass}>CircleCI / Terraform</li>
        <li className={listClass}>Docker</li>
      </ul>
    </section>
  );
}
