export function Social({}) {
  const iconClass =
    "cursor-pointer text-[2em] hover:text-[2.5em] transition-all text-accent";
  return (
    <section className="w-3/4 max-w-xl m-auto flex gap-5 mt-12 justify-center">
      <a href="https://www.linkedin.com/in/jonny-bauer/" target="_blank">
        <i className={`${iconClass} fa-brands fa-linkedin`}></i>
      </a>
      <a href="https://www.github.com/jonathonbauer" target="_blank">
        <i className={`${iconClass} fa-brands fa-github text-black`}></i>
      </a>
      <a href="mailto:info@jonnybauer.ca" target="_blank">
        <i className={`${iconClass} fa-solid fa-envelope`}></i>
      </a>
    </section>
  );
}
