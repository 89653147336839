export function Branding({}) {
  return (
    <section className="mx-auto">
      <div className="flex w-1/2 m-auto text-center justify-center align-center">
        <div
          className="bg-hero-image bg-contain bg-no-repeat bg-center h-[150px] w-[150px] min-w-[150px] sm:h-[250px] sm:w-[250px] sm:min-w-[250px]"
        />
      </div>

      <div className="mt-5 mb-2 mx-auto text-center flex flex-col justify-center">
        <h1 className="text-[1.75em] font-accent">Jonny Bauer</h1>
        <h2 className={`self-center text-[1.25em] mb-5 font-header`}>Full Stack Developer</h2>
      </div>
    </section>
  );
}
