import { Branding } from "./components/Branding";
import { Skills } from "./components/Skills";
import { Social } from "./components/Social";

function App() {
  return (
    <div className="App p-5 flex flex-col h-screen items-center justify-center">
      <div>
        <Branding />
        <Skills />
        <Social />
      </div>
    </div>
  );
}

export default App;
